<template>
  <section class="contact container">
    <h1 class="padding__sm text__center">Contact Us</h1>
    <section class="form__grid margin__md">
      <p>
        <i>You must be logged into a WOW account in order to complete this form.</i>
        If you are having trouble logging in, please fill out
        <router-link to="/my-account/login-issue" class="link__none text__bold">this form</router-link>.
        If this inquiry is in regards to a specific order, please reply to the email that was created when you submitted the order.
        This helps us keep track of all correspondence for a particular order and provide much better assistance to you.
        For general help, please visit our
        <router-link to="/help" class="link__none text__bold">help page</router-link>&nbsp;for assistance prior to filling out this form.
      </p>
      <section v-if="!submitted" class="contact-form">
        <label :class="[errors.topic ? 'form--error' : null ]">
          Topic*
          <select v-model="form.topic">
            <option value></option>
            <option value="Bulk Order Access">Batch Order Access</option>
            <option value="Website Issue">Website Problem</option>
            <option value="Product Idea">Product Suggestion</option>
            <option value="Product Issue">Product Issue</option>
            <option value="Other">Other</option>
          </select>
        </label>
        <form-error :message="errors.topic" />
        <label :class="[errors.subject ? 'form--error' : null ]">
          Subject*
          <input type="text" v-model="form.subject" placeholder="subject here" />
        </label>
        <form-error :message="errors.topic" />
        <label :class="[errors.message ? 'form--error' : null ]">
          Your Message*
          <Editor :api-key="apiKey" :init="config" v-model="form.message" />
        </label>
        <form-error :message="errors.message" />
        <p
          class="text__sm text__center"
        >Your message will be submitted to a ticketing queue for a WOW representative to view. Please be patient in waiting for a response.</p>
        <button class="btn btn__green text__md" @click="submit">Send Message</button>
      </section>
      <section v-else class="contact-complete">
        <p
          class="text__bold text__center"
        >Your message has been submitted, a WOW representative will be looking into your issue shortly</p>
        <router-link to="/" class="breadcrumb text__bold text__center">Back to Home Page</router-link>
      </section>
    </section>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { createError } from "../../../libs/flash-messages";
import { basicConfig } from "../../../plugins/tinymce";
export default {
  data() {
    return {
      isValidated: true,
      submitted: false,
      form: {
        email: "",
        name: "",
        topic: "",
        subject: "",
        message: ""
      },
      errors: {
        topic: null,
        subject: null,
        message: null
      }
    };
  },
  metaInfo: {
    title: "Contact Us | WOW"
  },
  computed: {
    ...mapGetters(["getUserDetails"]),
    apiKey() {
      return basicConfig.apiKey;
    },
    config() {
      return basicConfig.config;
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "createContactTicket"]),
    async submit() {
      if (
        !this.getUserDetails ||
        !this.getUserDetails.email ||
        !this.getUserDetails.displayName
      ) {
        createError(
          "Access Denied",
          "Only signed in users may submit this form"
        );
        return;
      }
      this.validate();
      if (!this.isValidated) return;
      this.startSetIsSubmitting({ bool: true, heading: "Sending Message" });
      try {
        await this.createContactTicket(this.form);
        this.startSetIsSubmitting({ bool: false });
        this.submitted = true;
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validate() {
      this.isValidated = true;
      this.errors.topic =
        this.form.topic === "" ? "Please select a topic" : null;
      this.errors.subject =
        this.form.subject === "" ? "Please enter a subject" : null;
      this.errors.message =
        this.form.message === "" ? "Please enter a message" : null;
      for (let key in this.errors) {
        if (this.errors[key] !== null) {
          this.isValidated = this.isValidated && false;
        } else {
          this.isValidated = this.isValidated && true;
        }
      }
    },
    setTopic() {
      if (this.$route.query.topic) {
        this.form.topic = "Bulk Order Access";
        this.form.subject = "Please Give Me Access to the Batch Order Form";
      }
    }
  },
  mounted() {
    if (this.getUserDetails) {
      this.form.email = this.getUserDetails.email;
      this.form.name = this.getUserDetails.displayName;
    }
    this.setTopic();
  }
};
</script>

<style scoped lang="scss">
.contact-complete {
  height: 200px;
  @include grid($cols: 1fr, $col-gap: 25px, $align: center);
}
</style>